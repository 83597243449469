export const bannerSizes = {
  halfGrid: {
    mobile: {
      width: 351,
      height: 300,
    },
    desktop: {
      width: 660,
      height: 400,
    },
  },
  horizontalGrid: {
    mobile: {
      width: 172,
      height: 160,
    },
    desktop: {
      width: 400,
      height: 194,
    },
  },
  quarterGrid: {
    desktop: {
      width: 1332,
      height: 400,
    },
  },
  verticalGrid: {
    mobile: {
      width: 351,
      height: 300,
    },
    desktop: {
      width: 248,
      height: 400,
    },
  },
} as const;
